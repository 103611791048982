import { useGetInvalidateQueryCache } from '../../../../hooks/use-fetch/use-query-fetch';
import {
  FetchUsersEmailsRequest,
  FetchUsersEmailsResponse,
} from './fetch-users-emails.types';
import { fetchUsersEmails } from './fetch-users-emails';
import { useGenericFetchQuery } from '../../../../hooks/use-generic-fetch-query';
import { getGenericQueryObj } from '../../../../hooks/use-generic-fetch-query/helpers/get-generic-query-obj';
import { GenericFetchProps } from '../../../../hooks/use-generic-fetch-query/use-generic-fetch-query.types';

const fetchUsersEmailsQueriesObj = getGenericQueryObj({
  groupName: 'fetch-users-emails',
  query: fetchUsersEmails,
});

export const useFetchUsersEmails = <TransformedData = FetchUsersEmailsResponse>(
  props?: GenericFetchProps<
    FetchUsersEmailsRequest,
    FetchUsersEmailsResponse,
    TransformedData
  >,
) => {
  return useGenericFetchQuery({
    fallbackErrorMessage: 'Unable to fetch users em.',
    queryObj: fetchUsersEmailsQueriesObj,
    ...props,
  });
};

export const useInvalidateFetchUsersEmailsCache = () => {
  const invalidateFetchUsersEmailsCache = useGetInvalidateQueryCache(
    fetchUsersEmailsQueriesObj,
  );

  return { invalidateFetchUsersEmailsCache };
};
