import { useGetInvalidateQueryCache } from '../../../../hooks/use-fetch/use-query-fetch';
import { GetTaxesArgs, GetTaxesResponse } from './get-taxes.types';
import { getTaxes } from './get-taxes';
import { useGenericFetchQuery } from '../../../../hooks/use-generic-fetch-query';
import { getGenericQueryObj } from '../../../../hooks/use-generic-fetch-query/helpers/get-generic-query-obj';
import { GenericFetchProps } from '../../../../hooks/use-generic-fetch-query/use-generic-fetch-query.types';

const getTaxesQueriesObj = getGenericQueryObj({
  groupName: 'get-taxes',
  query: getTaxes,
});

export const useGetTaxes = <TransformedData = GetTaxesResponse>(
  props?: GenericFetchProps<GetTaxesArgs, GetTaxesResponse, TransformedData>,
) => {
  return useGenericFetchQuery({
    fallbackErrorMessage: 'Unable to fetch taxes.',
    queryObj: getTaxesQueriesObj,
    ...props,
  });
};

export const useInvalidateGetTaxesCache = () => {
  const invalidateGetTaxesCache =
    useGetInvalidateQueryCache(getTaxesQueriesObj);

  return { invalidateGetTaxesCache };
};
