import axios from 'axios';
import { coreApiUrl } from '../../../../helpers/auth';
import {
  FetchUsersEmailsRequest,
  FetchUsersEmailsResponse,
} from './fetch-users-emails.types';

export const fetchUsersEmails = (request: FetchUsersEmailsRequest) => {
  return axios
    .get<FetchUsersEmailsResponse>(`${coreApiUrl}/users/get-users-list/`, {
      params: request,
    })
    .then(({ data }) => {
      return data;
    });
};
