import {
  Cell,
  Checkbox,
  ColorDetails,
  ColorPicker,
  Dropdown,
  SearchField,
  Stack,
  Toggle,
} from 'gantri-components';
import { useRecoilState, useResetRecoilState } from 'recoil';
import { productSizeOptions } from '../../../constants/options';
import { AttentionOption } from './stocks-filter.types';
import { stocksPageAtoms } from './stocks-filter.atoms';
import {
  assignedOptions,
  productionFilterOptions,
  stockAttentionAlertOptions,
  stocksStatusOptions,
} from './stocks-filter.constants';
import { OrdersSearchInput } from '../components/orders-search-input';
import { useGetAllProducts } from '../../../api/products/routes';
import { FilterGrid, FilterRow } from '../../common/filter-grid';
import { FilterResetLabel } from '../../common/filter-reset-label';
import { AtomsRadioList } from '../../common/atoms-radio-list';
import { AtomsCheckboxList } from '../../common/atoms-checkbox-list';

export const StocksFilter = () => {
  const [assigned, setAssigned] = useRecoilState(
    stocksPageAtoms.filters.assigned,
  );
  const [color, setColor] = useRecoilState(stocksPageAtoms.filters.color);
  const [highPriority, setHighPriority] = useRecoilState(
    stocksPageAtoms.filters.highPriority,
  );
  const [productId, setProductId] = useRecoilState(
    stocksPageAtoms.filters.productId,
  );
  const [statuses, setStatuses] = useRecoilState(
    stocksPageAtoms.filters.statuses,
  );

  const isProductionFilterApplied = !(
    productionFilterOptions.length !== statuses.length ||
    productionFilterOptions.some((option) => {
      return !statuses.includes(option);
    })
  );

  const { data, isLoading: isLoadingAllProducts } = useGetAllProducts();
  const allProducts = data?.products || [];

  const resetStatuses = useResetRecoilState(stocksPageAtoms.filters.statuses);

  const assignedValue = assignedOptions.find(({ outputValue }) => {
    return outputValue === assigned;
  })?.value;

  const toggleProdFilter = () => {
    if (isProductionFilterApplied) {
      resetStatuses();
    } else {
      setStatuses(productionFilterOptions);
    }
  };

  return (
    <FilterGrid>
      <FilterRow>
        <FilterResetLabel
          atom={stocksPageAtoms.filters.productId}
          default={stocksPageAtoms.defaults.filters.productId}
          text="Item"
        />
        <SearchField
          idProperty="id"
          items={[
            {
              fullProductName: 'Select product...',
              id: stocksPageAtoms.defaults.filters.productId,
            },
            ...allProducts,
          ]}
          labelProperty="fullProductName"
          placeholder="Select product..."
          processing={isLoadingAllProducts}
          value={productId}
          onSelect={(item) => {
            return setProductId(item?.id);
          }}
        />
      </FilterRow>

      <FilterRow>
        <FilterResetLabel
          atom={stocksPageAtoms.filters.color}
          default={stocksPageAtoms.defaults.filters.color}
          text="Color"
        />
        <ColorPicker
          allowMultipleSelection={false}
          value={color}
          onValueChange={(color: ColorDetails) => {
            setColor(color?.code);
          }}
        />
      </FilterRow>

      <FilterRow>
        <FilterResetLabel
          atom={stocksPageAtoms.filters.size}
          default={stocksPageAtoms.defaults.filters.size}
          text="Size"
        />
        <AtomsRadioList
          atom={stocksPageAtoms.filters.size}
          gridProps={{
            columns: 2,
          }}
          idProperty="code"
          items={productSizeOptions}
          labelProperty="name"
        />
      </FilterRow>

      <FilterRow>
        <FilterResetLabel
          atom={stocksPageAtoms.filters.statuses}
          default={stocksPageAtoms.defaults.filters.statuses}
          text="Status"
        />
        <Stack gap="3x">
          <Checkbox
            checked={isProductionFilterApplied}
            labelText="Production Filter"
            onSelected={toggleProdFilter}
          />
          <AtomsCheckboxList
            atom={stocksPageAtoms.filters.statuses}
            gridProps={{ columns: 2 }}
            items={stocksStatusOptions}
          />
        </Stack>
      </FilterRow>

      <FilterRow>
        <FilterResetLabel
          atom={stocksPageAtoms.filters.orderId}
          default={stocksPageAtoms.defaults.filters.orderId}
          text="Order #"
        />
        <OrdersSearchInput atom={stocksPageAtoms.filters.orderId} />
      </FilterRow>

      <FilterRow>
        <FilterResetLabel
          atom={stocksPageAtoms.filters.assigned}
          default={stocksPageAtoms.defaults.filters.assigned}
          text="Assigned"
        />
        <Dropdown
          items={assignedOptions}
          value={assignedValue}
          onSelect={({ outputValue }: AttentionOption) => {
            setAssigned(outputValue);
          }}
        />
      </FilterRow>

      <FilterRow>
        <FilterResetLabel
          atom={stocksPageAtoms.filters.attention}
          default={stocksPageAtoms.defaults.filters.attention}
          text="Attention alerts"
        />
        <AtomsCheckboxList
          atom={stocksPageAtoms.filters.attention}
          items={[...stockAttentionAlertOptions]}
        />
      </FilterRow>

      <FilterRow>
        <FilterResetLabel
          atom={stocksPageAtoms.filters.highPriority}
          default={stocksPageAtoms.defaults.filters.highPriority}
          text="Priority only"
        />
        <Cell justifyContent="start">
          <Toggle
            name="Priority"
            value={highPriority}
            onSelected={setHighPriority}
          />
        </Cell>
      </FilterRow>
    </FilterGrid>
  );
};
