import { DiscountType, OrderStock } from '../../new-order.type';
import { TaxableItem } from '../../../../api/orders/routes/get-taxes/get-taxes.types';
import { transactionTypesMap } from '../../../../constants/options';

export const getSubtotal = (
  orderType: string,
  stocks: OrderStock[],
): number => {
  const shouldUseManufacturerPrice = orderType === 'Third Party';

  return stocks.reduce((acc, current) => {
    return (
      acc +
      (shouldUseManufacturerPrice
        ? current.product.manufacturerPrice
        : current.product.price) *
        (current.quantity || 0)
    );
  }, 0);
};

export const canEnableDiscount = (orderType: string): boolean => {
  return ['Trade', 'Wholesale'].includes(orderType);
};

export const getTaxableStocks = (stocks: OrderStock[]): TaxableItem[] => {
  return stocks.reduce((acc, stock) => {
    const { product, quantity } = stock;
    const item: TaxableItem = {
      amount: { subtotal: product.price },
      sku: product.sku,
      type: 'product',
    };

    return [...acc, ...new Array(quantity || 0).fill(item)];
  }, []);
};

export const getHasTaxes = (props: {
  hasResaleCertificate: boolean;
  orderType: string;
}): boolean => {
  const { hasResaleCertificate, orderType } = props;

  if (
    orderType === transactionTypesMap.thirdParty ||
    (orderType === transactionTypesMap.trade && !hasResaleCertificate)
  ) {
    return true;
  }

  return false;
};

export const getTotalDiscount = (
  discountType: DiscountType,
  discount: number,
  subtotal: number,
): number => {
  if (discountType === 'static') return (discount || 0) * 100;

  return subtotal * (Number(discount || 0) / 100);
};

export const getTotal = (
  subtotal: number,
  totalDiscount: number,
  shippingFee: number,
  taxes: number,
): number => {
  return Math.max(
    subtotal - totalDiscount + Number((shippingFee || 0) * 100) + (taxes || 0),
    0,
  );
};
