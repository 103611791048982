import axios from 'axios';
import { libApiUrl } from '../../../../helpers/auth';
import { GetTaxesArgs, GetTaxesResponse } from './get-taxes.types';

/** Number in cents. */
export const getTaxes = (body: GetTaxesArgs) => {
  return axios
    .post<GetTaxesResponse>(`${libApiUrl}/tax`, body)
    .then(({ data }) => {
      return data;
    });
};
