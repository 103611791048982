import { v4 as uuidv4 } from 'uuid';
import { useState } from 'react';
import { FormState } from './new-order.type';
import { useUploadBlob } from '../../helpers/firebase';
import { CreateTransactionArgs } from '../../api/transactions/routes/create-transaction/create-transaction.types';
import { useCreateOrderMutation } from '../../api/transactions/routes/create-transaction';
import { useNotification } from '../../hooks/useNotification';
import { useRouter } from '../../hooks';
import routePaths from '../../config/route-paths';
import { useInvalidateFetchUsersEmailsCache } from '../../api/products/routes/fetch-users-emails';

export const useNewOrder = () => {
  const uploadBlob = useUploadBlob();
  const { navigate } = useRouter();
  const { isLoading, onCreateOrder } = useCreateOrderMutation();
  const { notifyError } = useNotification();
  const [isUploading, setUploading] = useState<boolean>(false);

  const { invalidateFetchUsersEmailsCache } =
    useInvalidateFetchUsersEmailsCache();

  const onCreate = async (values: FormState) => {
    try {
      const hasDiscount = ['Trade', 'Wholesale'].includes(values.orderType);
      const isNewUser = !values.userSelected?.id;
      const hasAddress = values.shippingType !== 'doNotShip';
      const hasLocationInstruction =
        values.shippingType === 'other' &&
        !!values.locationInstruction &&
        !!values.locationInstruction?.fileBlob;

      const hasNewResaleCertificate = !!values.resaleCertificate?.fileBlob;

      const locationInstructionPath = hasLocationInstruction
        ? `orders/${uuidv4()}/instruction-location`
        : null;

      const resaleCertificatePath = hasNewResaleCertificate
        ? isNewUser
          ? `users/${values.userSelected.email}/resale-certificate`
          : `users/${values.userSelected.id}/resale-certificate`
        : null;

      setUploading(true);

      let resaleFullUrl = hasNewResaleCertificate
        ? null
        : values.resaleCertificate?.fileUrl;

      if (!!resaleCertificatePath) {
        resaleFullUrl = (await uploadBlob({
          blob: values.resaleCertificate.fileBlob,
          displayLoader: false,
          filename: values.resaleCertificate.fileName,
          hideUploadNotification: true,
          metadata: values.resaleCertificate.metadata,
          path: resaleCertificatePath,
          replacing:
            hasNewResaleCertificate && !!values.resaleCertificate?.fileUrl,
        })) as string;
      }

      let instructionLocationFullUrl = null;

      if (!!locationInstructionPath) {
        instructionLocationFullUrl = (await uploadBlob({
          blob: values.locationInstruction.fileBlob,
          displayLoader: false,
          filename: values.locationInstruction.fileName,
          hideUploadNotification: true,
          metadata: values.locationInstruction.metadata,
          path: locationInstructionPath,
        })) as string;
      }

      const payload: CreateTransactionArgs = {
        address: hasAddress ? values.selectedAddress : { doNotShip: true },
        discount: hasDiscount
          ? values.discountType === 'static'
            ? Number(values.discount) * 100
            : Number(values.discount)
          : null,
        discountType: values.discountType,
        email: values.email,
        invoice: values.invoice,
        locationInstruction: instructionLocationFullUrl,
        notes: values.notes,
        resaleCertificate: resaleFullUrl,
        shipCost: hasDiscount ? Number(values.shippingFee) * 100 : null,
        shippingType: hasAddress ? values.shippingType : 'do_not_ship',
        stocks: values.stocks.map(({ product, quantity }) => {
          return {
            product: { id: Number(product.id), sku: product.sku },
            quantity,
          };
        }),
        type: values.orderType,
      };

      await onCreateOrder(payload, {
        onSuccess: async (data) => {
          if (data?.success) {
            await invalidateFetchUsersEmailsCache();

            navigate(`${routePaths.orders}/${data.order.id}`);
          }
        },
      });
    } catch (e) {
      notifyError('Unable to create order.');
    } finally {
      setUploading(false);
    }
  };

  return {
    isProcessing: isLoading || isUploading,
    onCreate,
  };
};
